@import url(https://fonts.googleapis.com/css2?family=Noto+Rashi+Hebrew:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Serif+Hebrew:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&family=Noto+Serif+Hebrew:wght@500&family=Permanent+Marker&family=Rock+Salt&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&family=Noto+Serif+Hebrew:wght@500&family=Permanent+Marker&family=Rock+Salt&family=Zen+Kurenaido&display=swap);
* {
	font-family: 'Zen Kurenaido', sans-serif;
}

.card {
	background-color: white;
	margin: 0;
	position: absolute;
	left: 24.8vw;
	top: 20vh;
	height: 50vh;
	width: 50vw;
	border-style: solid;
	border-color: black;
}

.textSection {
	height: 100%;
	display: flex;
  	align-items: center;
	justify-content: center;
	overflow: auto;
	flex-direction: column;
	margin: auto;
}

.textSection h1 {
	margin: auto;
	padding-left: 5%;
	padding-right: 5%;
	font-size: 10vmin;
	text-align: center;
	white-space: pre-line;
	font-family: 'Zen Kurenaido', sans-serif;
}

.cardButtons {
	display: flex;
	flex-direction: row;
	width: 50.59vw;
	height: 10%;
	position: absolute;
	top: 73vh;
	left: 24.8vw;
}

.cardButtons button {
	/* flex: 1; */
	font-weight: bold;
	font-size: 5vmin;
	text-align: center;
	padding: 0;
	margin-left: 3px;
	border-style: solid;
	border-color: black;
	border-width: thin;
	height: 100%;
	width: 33.33%;
	background-color: #c1f0c1;
}


@media (hover: hover) {
	.cardButtons button:hover {
		background-color: #6fdc6f;
		cursor: pointer;
	}
}

.navbar {
	width: 100%;
	height: 40px;
	display: flex;
	flex-direction: row;
	position: absolute;
	top: -4px;
	left: 0;
	background-color: white;
}

.navbar button {
	/* flex: 1; */
	border-style: solid;
	border-width: thin;
	border: none;
	height: 100%;
	width: 20%;
	background-color: #ffd699;
}

.navbar button:hover {
	background-color: #ffad33;
}

.imgContainer {
	justify-content: center;
	display: flex;
	height: 95%;
	width: 100%;
	position: absolute;
	top: 5%;
	left: 0;
}

img {
	height: auto;
	width: auto;
}

.quiz {
	position: absolute;
	height: calc(100% - 30px);
	top: 30px;
	left: 0;
	width: 100%;
}

.quizSelectionBackground {
	position: absolute;
	top: 0;
	left: 0;
	height: 300%;
	width: 100%;
	background-color: #b3d9ff;
	z-index: -1;
}

.question {
	position: absolute;
	height: 40%;
	width: 100%;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.question .phraseQuiz {
	font-size: 4vmin;
	padding-right: 100px;
	padding-left: 100px;
}

.question h1 {
	font-size: 13vmin;
}

.answersContainer {
	position: absolute;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	width: 100%;
	height: 60%;
	top: 40%;
}

.answerText {
	position: absolute;
	height: 50%;
	width: calc(50% - 10px);
	text-align: center;
	z-index: 1;
	font-size: 5vmin;
	padding-left: 5px;
	padding-right: 5px;
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: none;
	white-space: pre-line;
}

.answerButton {
	height: 50%;
	width: 50%;
	max-height: 100%;
	position: absolute;
	aspect-ratio: 1/1;
	flex: 1 1;
	border-style: solid;
	border-width: thin;
	border-color: black;
	background-color: white;
}

@media (hover: hover) {
	.answerButton:hover {
		background-color: #b3d9ff;
	}
}

.progress {
	width: 80%;
	height: 10%;
	top: 10px;
	position: absolute;
	left: 2%;
	font-weight: bolder;
	font-size: 4.5vmin;
}

.fontToggle {
	width: 77px;
	height: 63px;
	position: fixed;
	top: 45px;
	right: 5px;
	border: none;
	outline: 1px black solid;
	background-color: #ffb3b3;
	color: black;
}

.fontToggle:hover {
	cursor: pointer;
}

.dictionaryList {
	position: absolute;
	left: 3%;
	top: 30px;
	font-size: 200%;
}

.endOfQuizPrompt {
	position: absolute;
	width: 98.5%;
	height: 95%;
	top: 30px;
	left: 1.5vw;
	padding-bottom: 5%;
}

.difficultyPrompt {
	width: 98%;
	height: 95%;
	display: flex;
	justify-content: center;
	position: absolute;
	top: 50px;
	text-align: center;
}

.difficultyPrompt h1 {
	height: 10%;
}

.difficultyCol {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 70px;
	height: 100%;
	position: relative;
	top: 60px;
	font-weight: bolder;
	font-size: 12px;
}

.difficultyCol h4 {
	height: 30px;
	margin: 0;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	font-size: 12px;
}

#blankNum {
	color: transparent;
}

.blankButton {
	pointer-events: none;
	background-color: transparent;
	border: none;
}

.difficultyCol .numButton {
	pointer-events: none;
	background-color: transparent;
	border: none;
	width: 60px;
	font-size: 12px;
}

#numCol {
	width: 60px;
}

.difficultyCol h2 {
	margin: 0;
}

.selectionWrapper {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 70px;
	overflow-x: scroll;
}

.buttonWrapper {
	position: absolute;
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	height: 150%;
	left: 0;
	top: 0;
	text-align: center;
}

.buttonWrapper button {
	width: 50px;
	height: 50px;
	border-width: thin;
	font-size: 12px;
}

.buttonWrapper button:hover {
	background-color: #ffad33;
}

th, td {
	padding: 1vw;
	font-size: 4vmin;
	white-space: pre-line;
}

table, th, td {
	border: 1px solid black;
    border-collapse: collapse;
}

table {
	width: 100%;
}

#highscores {
	background-color: white;
}

#highscores th {
	background-color: #ffb3b3;
}

#highscores tbody tr td:first-of-type{
	background-color: #d9d9d9;
}

.highscore {
	text-align: center;
	font-size: 15px;
}

.progressBackground {
	background-color: #b3d9ff;
	z-index: -1;
	position: absolute;
	top: 0;
	left: 0;
	height: 600%;
	width: 100%;
}

.accountBackground {
	background-color: #b3d9ff;
	z-index: -1;
	position: absolute;
	top: 0;
	left: 0;
	height: 200%;
	width: 100%;
}

.scoreBoardTh {
	font-size: 15px;
	width: 20%;
}

#returnToQuizzes {
	position: absolute;
	top: 50px;
	height: 40px;
	width: 90vw;
	left: 3.5vw;
	margin: 0 0 0 0;
	padding: 0 0 0 0;
	font-size: 18px;
	font-weight: bold;
	align-self: center;
	border-radius: 4px;
	border-width: thin;
}


@media (hover: hover) {
	#returnToQuizzes:hover {
		background-color: #ffad33;
	}
}

.quizResultsWrapper {
	position: absolute;
	top: 120px;
	width: 97vw;
	font-size: 6vmin;
}

.quizResultsWrapper h1 {
	margin: 5px;
}

.flashcardsWrapper {
	position: absolute;
	top: 35px;
	left: 0;
	height: 150%;
	width: 100%;
	background-color: #b3d9ff;
}

.cardBackground {
	background-color: #b3d9ff;
	z-index: -1;
	position: absolute;
	top: 0;
	left: 0;
	height: 300%;
	width: 100%;
}

.deckWrapper {
	position:absolute;
	width: 50vw;
	left: 25%;
	height: 8vh;
	bottom: 80%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.deck {
	text-align: center;
	font-size: 4vw;
	margin: 0;
}

.nextDeck {
	position: absolute;
	bottom: 20%;
	left: 93%;
	width: 0;
	height: 0;
	border-right-color: transparent;
	background-color: transparent;
	border-top: 3vh solid transparent;
	border-left: 3.5vw solid black;
	border-bottom: 3vh solid transparent;
}

.nextDeck:hover {
	cursor: pointer;
	border-left-color: #ffad33;
}

.prevDeck:hover {
	cursor: pointer;
	border-right-color: #ffad33;
}

.prevDeck {
	position: absolute;
	bottom: 20%;
	right: 93%;
	width: 0;
	height: 0;
	border-left-color: transparent;
	background-color: transparent;
	border-top: 3vh solid transparent;
	border-right: 3.5vw solid black;
	border-bottom: 3vh solid transparent;
}

form {
	width: 50%;
	position: absolute;
	top: 25vh;
	left: 25vw;
}

input {
  width: 100%;
  margin: 8px 0;
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #ccc;
}

button[type=submit] {
  width: 100%;
  background-color: #ffad33;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button[type=submit]:hover {
  background-color: #ff9900;
}

#hyperButton {
  background: none!important;
  border: none;
  padding: 0!important;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
  font-size: 100%;
}

#title {
	padding-left: 20px;
	margin: -8px;
	font-family: 'Rock Salt', cursive;
	font-size: 6vmin;
	background-color: #ffad33;
}

select {
	width: 100%;
	padding: 10px;
	border-radius: 4px;
	margin: 8px 0;
	border: 1px solid #ccc;
}

.myAccountWrapper {
	position: absolute;
	top: 90px;
	padding: 0 10px;
	overflow-x: scroll;
	-webkit-overflow-scrolling: touch;
	width: calc(100% - 20px);
	left: 0;
	margin-left: 0;
}

.myAccountWrapper th, td {
	width: 70px;
	height: 70px;
}

.myAccountWrapper table {
	width: 770px;
	height: 420px;
}

.myAccountWrapper h2 {
	text-decoration: underline;
}

.accountTitle {
	background-color: #99ff99;
	width: calc(100% - 20px);
	position: absolute;
	left: 0;
	top: 14px;
	padding: 10px;
}

.logout {
	font-weight: bold;
	border-style: solid;
	border-width:  thin;
	border-radius: 4px;
	height: 33px;
	width: 66px;
}

.logout:hover {
	background-color: #ffad33;
	cursor: pointer;
}

@keyframes progressAnimation {
	from {width: 0;}
	to {width: 80vmin}
}

.progressBarContainer {
	background-color: #b3b3b3;
	width: 80vmin;
	height: 28px;
	border-radius: 100px 100px 100px 100px;
	/* outline: solid 1px black; */
}

.progressBar {
	background-color: transparent;
	width: 80vmin;
	height: 28px;
	border-radius: 100px 100px 100px 100px;
	animation-name: progressAnimation;
	animation-duration: 2s;
}

.progressBarContents {
	background-color: green;
	border-radius: 100px 100px 100px 100px;
	height: 28px;
}

.progressBarContents p {
	color: white;
	padding-left: 15px;
	font-size: 110%;
}

.aishLogo {
	position: fixed;
	bottom: 1vmin;
	right: 1vmin;
	height: 7.5vmin;
	width: 15vmin;
	pointer-events: none;
}

.verifyForm {
	border-style: solid;
	border-radius: 6px;
	height: 300px;
	width: 300px;
	margin-top: 10vmin;
	position: absolute;
	padding: 0px 10px 0px 10px;
	left: calc(50% - 170px);
}

#verificationSent {
	height: 10%;
	margin: 0px 0px 30px 0;
}

#verifyButtons {
	position: absolute;
	left: 10px;
	bottom: 10px;
	width: 300px;
	height: 50px;
}

#verifyButtons button {
	font-weight: bold;
	font-size: 16px;
	text-align: center;
	border-radius: 4px;
	border-style: solid;
	border-color: black;
	border-width: thin;
	height: 100%;
	width: 150px;

}

#verifyButtons button:hover {
	background-color: #ffad33;
}

.dropWrapper {
	position: absolute;
	top: 78px;
	left: 10px;
	width: 350px;
	height: 150%;
}

.dropdown-menu {
    height: 35px;
    list-style: none;
    margin: 0;
    padding: 0;
    float: left;
    text-align: center;
}

#deckSelector {
	position: absolute;
	top: 15px;
	left: 10px;
}

/* invisible first li, lazy solution */
.dropdown-menu > li:first-child {
	outline: none;
	background-color: transparent;
	color: transparent;
}

#deckSelector > li:first-child {
	outline: 1px solid black;
	background-color: #ffb3b3;
	color: black;
}

.dropdown-menu li {
    display: inline-block;
    position: relative;
    float: left;
    width: 220px;
    line-height: 35px;
    text-decoration: none;
	background-color: #ffb3b3;
	outline: solid 1px black;
	color: black;
}

.dropdown-menu li li a {
    font-size: 12px;
}

li > ul li:hover {
    background: #ff6666;
}

/*--- Sublist Styles ---*/
.dropdown-menu ul {
    position: absolute;
    /* display: none; */
    padding: 0;
}

#deckSelector ul {
    display: none;
}

/*--- Hide Sub Sublists ---*/
.dropdown-menu li ul ul {
    display: none;
}

.dropdown-menu li:hover ul ul {
    display: none;
}

#deckSelector li:hover ul ul {
    display: none;
}

/*--- Sublevel UL's display and position on hover ---*/
.dropdown-menu li:hover ul {
    display: block;
}

#deckSelector li:hover ul {
    display: block;
}

.dropdown-menu li li:hover ul {
    margin-left: 221px;/*This must be width of menu box*/
    margin-top: -35px;
	cursor: pointer;
	display: block;
}

#deckSelector li li:hover ul {
	display: block;
}

.dropdown-menu li ul li ul li {
	width: 100px;
	height: 105px;
	white-space: pre-line;
}

nu-li {
    padding: 10px;
}

.dropdown-submenu {
    position: absolute;
    left: 0px;
}

